import React from "react";
import SEO from "../components/seo";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import opsio from "../images/logo_opsio.svg";
import devopsImage from "../images/devopsImage.jpg";
import "react-phone-input-2/lib/style.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "../styles/contact.scss";
import aws from "../images/aws-logo.svg";
import microsoft from "../images/micro.svg";
import google from "../images/google.svg";
import "../styles/about.scss";
import "../styles/home.scss";
import branas from "../images/branas.svg";
import forlaget from "../images/forlaget.svg";
import politico from "../images/politico.svg";
import lofbergs from "../images/lofbergs.svg";
import Eow from "../images/eraofwe.svg";
import opus from "../images/opus.png";
import savr from "../images/savr.png";
import silverrail from "../images/silverrail.webp";
import integrate from "../images/Integrated.svg";
import competency from "../images/Competency.svg";
import service from "../images/as_a_service.svg";
import Footer from "../components/footer";
import { Contact } from "../components/campaign/Contact";
import vmware from "../images/vmware.svg";
import OpsioAWSPartnership from "../components/campaign/OpsioAWSPartnership";
import consultAwsPartner from "../images/awspartner.png";
import consultAzurePartner from "../images/azure_logo.png";

const Campaign = ({ data }) => {
  const {
    HeroSection: { cta, des, heading1, heading2, title },
    Consulting: {
      title: consultingTitle,
      heading,
      des: consultingDes,
      bulletPointsWithDesc,
      placeholders,
      checkbox1,
      checkbox2,
    },
    AboutUs: {
      title: aboutTitle,
      des1,
      des2,
      imageHeading,
      rightSectionHeading,
      rightSection,
    },
    testimonial: { title: testimonialTitle, testimonials },
    clients: { title: clientTitle },
    partnership,
    MetaDes,
    MetaTitle,
    slug,
    locale,
  } = data.allStrapiCampaigns.edges[0].node;

  const getFooter = data.allStrapiLayout.edges[0].node;

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcbmXUdAAAAAM8BACZCq5rzXPHfn1BpVeNv5f5o">
      <SEO
        slug={slug}
        locale={locale}
        title={MetaTitle}
        description={MetaDes}
        noindexForce={true}
      />
      <div className="service_upper_class_campaign">
        <div
          className="serviceHeroSectionCampaignOnly d-md-flex flex-md-column  px-md-5 px-4 pb-0 justify-content-md-between  position-relative"
          id="heading"
        >
          <div>
            <div>
              <div className="mb-md-5 pb-md-5 mb-5">
                <img src={opsio} alt="logo" />
              </div>
              <div className=" mb-5 pb-5">
                <div class="d-md-flex flex-column align-items-md-center justify-content-md-center d-flex justify-content-center text-center">
                  <div className="serviceHeroSectionTitle text-uppercase  text-center m-0 mb-3">
                    {title}
                  </div>
                  <h1 className="serviceHeroSectionHeading  text-center mx-0 mb-0">
                    {heading1}
                  </h1>
                  <h1 className="serviceHeroSectionHeading  text-center mx-0 mb-3">
                    <span style={{ color: "#58C9E8" }} className="m-0">
                      {heading2}
                    </span>
                  </h1>
                  <div
                    className="serviceHeroSectionDescription w-custom-100   text-center  me-0 ms-0 my-4"
                    dangerouslySetInnerHTML={{ __html: des }}
                  />

                  <div className="opsBtnwrap d-flex justify-content-center justify-content-md-start">
                    <Link className="opsBtnlink " to="#contact-us">
                      <p>{cta}</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {partnership.azureImageId}
            {partnership.azureImageId && (
              <div className="d-md-flex justify-content-md-end campaign-image-container">
                <img src={consultAzurePartner} className="campaign-image" />
              </div>
            )}
            {partnership.awsImageId && (
              <div
                className={`d-md-flex justify-content-md-end ${
                  partnership.azureImageId && partnership.awsImageId
                    ? "campaign-image-aws-container"
                    : "campaign-image-container"
                }`}
              >
                <img src={consultAwsPartner} width="250px" height="100px" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="ourApproachWhiteOfferings serviceHotspot">
        <div className="containerwrap">
          <div className="approchTitle">{consultingTitle}</div>
          <div className="approchHeading mb-0">{heading}</div>
          <div
            className="approchdescription mt-5"
            dangerouslySetInnerHTML={{ __html: consultingDes }}
          />
        </div>
      </div>
      <div className="processOpsioOfferings serviceHotspot py-5 ">
        <div className="mx-md-5 mx-3">
          <div className="prorcss_wrap w-100" id="contact-us">
            <div className="d-md-flex justify-content-md-between">
              <div className="processRightSectionServices w-100">
                <div className="inner_process d-md-flex justify-content-md-center w-100">
                  <div className="inner_process_left d-md-flex  w-100 col-md-12 col-12 flex-md-wrap">
                    {bulletPointsWithDesc &&
                      bulletPointsWithDesc.map((bulletPoint, index) => (
                        <div
                          className="processDetailsDesCampaign col-md-12 col-12"
                          key={index}
                        >
                          <div className="number">
                            <>{index < 9 ? `0${index + 1}` : `${index + 1}`}</>
                          </div>
                          <div className="process_content_title mb-3">
                            {bulletPoint.title}
                          </div>
                          <div className="process_content_solution">
                            {bulletPoint.des}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <Contact
                placeholders={placeholders}
                checkbox1={checkbox1}
                checkbox2={checkbox2}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="aboutUs py-5">
        <div className="containerwrap-about d-md-flex align-items-start pt-5">
          <div className="aboutUsLeftSectionCampaign col-md-6 col-12">
            <div className="aboutUsTitle">{aboutTitle}</div>
            <div className="aboutUsDescription" style={{ color: "white" }}>
              {des1}
            </div>
            <div className="aboutUsDescription mt-3" style={{ color: "white" }}>
              {des2}
            </div>
            <div className="aboutUslogoSection">
              <div className="aboutUslogoSectionTitle">
                <div className="title">{imageHeading}</div>
                <div className="brands">
                  <figure>
                    <img
                      className="pt-2"
                      width="50"
                      src={aws}
                      alt="Amazon Web Services"
                    />
                  </figure>
                  <figure>
                    <img width="200" src={microsoft} alt="Azure" />
                  </figure>
                  <figure>
                    <img width="120" src={google} alt="Google Cloud" />
                  </figure>
                  <figure>
                    <img width="120" src={vmware} alt="VM Ware" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutUsRightSectionCampaign col-md-6 col-12">
            <div className="aboutUsTitle text-center">
              {rightSectionHeading}
            </div>
            <div className=" d-md-flex justify-content-md-center d-flex flex-column flex-md-row gap-5 flex-wrap">
              {rightSection.map(({ title, des }, index) => (
                <div className="card   px-4 align-items-md-center  ">
                  <div className="mb-2">
                    {index === 0 && (
                      <img
                        src={integrate}
                        alt={title}
                        width="70px"
                        height="60px"
                      />
                    )}
                    {index === 1 && (
                      <img
                        src={competency}
                        alt={title}
                        width="80px"
                        height="60px"
                      />
                    )}
                    {index === 2 && (
                      <img
                        src={service}
                        alt={title}
                        width="60px"
                        height="60px"
                      />
                    )}
                  </div>
                  <div className="d-md-flex flex-md-column text-md-start text-center ms-md-4">
                    <div className="card-title">{title}</div>
                    <div className="card-des ">{des}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <OpsioAWSPartnership data={partnership} isCampaignPage={true} />
      <div className="successStories">
        <div className="successSwapCampaign">
          <div className="successStoriesHeading mt-0 pt-0">{clientTitle}</div>

          <div className="successStoriesImages">
            <div className="inside_stories flex-wrap mt-md-4">
              <div className="d-md-flex">
                <div className="success_img ">
                  <a href="https://www.savr.com/" target="_blank">
                    <img
                      className="successStoriesImgCampaign"
                      id="savr"
                      alt="SAVR"
                      src={savr}
                    />
                  </a>
                </div>
                <div className="success_img ">
                  <a href="https://www.silverrailtech.com/" target="_blank">
                    <img
                      className="successStoriesImgCampaign"
                      id="silverrail"
                      alt="SilverRail"
                      src={silverrail}
                    />
                  </a>
                </div>
              </div>
              <div className="d-md-flex align-items-md-center mt-md-5 mt-3">
                <div className="success_img">
                  <a
                    href="https://www.branas.se/om-branasgruppen/"
                    target="_blank"
                  >
                    <img
                      className="successStoriesImg"
                      alt="branas"
                      src={branas}
                    />
                  </a>
                </div>
                <div className="success_img">
                  <a href="http://www.okforlaget.se/" target="_blank">
                    <img
                      className="successStoriesImg"
                      alt="forlaget"
                      src={forlaget}
                    />
                  </a>
                </div>
                <div className="success_img">
                  <a href="https://en.lofbergs.se/" target="_blank">
                    <img
                      className="successStoriesImg"
                      alt="politico"
                      src={politico}
                    />
                  </a>
                </div>
                <div className="success_img">
                  <a href="https://www.politico.com/" target="_blank">
                    <img
                      className="successStoriesImg"
                      alt="lofbergs"
                      src={lofbergs}
                    />
                  </a>
                </div>
                <div className="success_img">
                  <a href="https://www.eraofwe.com/" target="_blank">
                    <img
                      className="successStoriesImg"
                      id="eraofwe"
                      alt="lofbergs"
                      src={Eow}
                    />
                  </a>
                </div>
                <br />
                <br />
                <div className="success_img">
                  <a href="https://www.opus.se/" target="_blank">
                    <img
                      className="successStoriesImg"
                      id="opus"
                      alt="Opus"
                      src={opus}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="processOpsioOfferings  pt-5 pb-0 serviceHotspot ">
        <div className="containerwrap d-flex justify-content-center">
          <div className="prorcss_wrap">
            <div className="ourApproachWhiteCampaign d-md-flex justify-content-md-center serviceHotspot pt-0 w-100 mb-3">
              <div className="approchTitle pb-md-5">{testimonialTitle}</div>
            </div>

            <div className="d-md-flex">
              <div className="processRightSectionCampaigns col-md-12 col-12 w-100">
                <div className="inner_process d-md-flex justify-content-md-center w-100 mt-3 mt-md-0 mt-lg-0 col-md-12 col-12">
                  <div className="inner_process_left d-md-flex justify-content-md-center w-100 col-md-12 col-12">
                    {testimonials.map((highlight, index) => (
                      <div
                        className="d-md-flex flex-md-column align-items-md-center col-md-6 col-12"
                        key={index}
                      >
                        <div className={`processDetails d-md-flex  `}>
                          <div className="number ms-3 ms-md-0">
                            <div className="d-flex justify-content-center ">
                              <img src={user} alt="user icon" className="imageSize"/>
                            </div>
                          </div>
                          <div className="d-md-flex flex-md-column justify-content-md-center ms-md-4 col-md-8 col-12 ms-3 ms-md-0">
                            <div className="process_content text-md-start text-center">
                              {highlight.des}
                            </div>
                            <div className="process_content text-md-end mt-2 mt-md-0 text-center">
                              -{highlight.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer
        data={getFooter.navbar.footer}
        requirements={getFooter.navbar.requirements}
        locale={getFooter.locale}
        hideCta={false}
        campaignLink={"#contact-us"}
      />
    </GoogleReCaptchaProvider>
  );
};

export default Campaign;

export const query = graphql`
  query CampaignPage($slug: String!, $locale: String!) {
    allStrapiCampaigns(
      filter: { slug: { eq: $slug }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          AboutUs {
            des1
            des2
            imageHeading
            rightSection {
              des
              title
            }
            rightSectionHeading
            title
          }
          Consulting {
            checkbox1
            checkbox2
            des
            heading
            title
            bulletPointsWithDesc {
              des
              id
              title
            }
            placeholders {
              email
              message
              name
              organization
              file
              btn
            }
          }
          clients {
            title
          }
          testimonial {
            title
            testimonials {
              des
              name
            }
          }
          partnership {
            mainTitle
            intro
            heading
            advantages {
              title
              des
            }
            awsImageId
            azureImageId
            approach {
              title
              heading
              desc
              bulletPoints {
                id
                title
              }
            }
            des1
            des2
          }
          MetaDes
          MetaTitle
          slug
          HeroSection {
            cta
            des
            heading1
            heading2
            title
          }
        }
      }
    }
    allStrapiLayout(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          locale
          navbar {
            requirements {
              btn
              email
              des
              title
            }
            footer {
              heading
              contact {
                email
                phone
                address1
                address2
              }
              values {
                COMPANY {
                  name
                  src
                }
                INDUSTRIES {
                  name
                  src
                }
                PLATFORMS {
                  name
                  src
                }
                RESOURCES {
                  name
                  src
                }
                SERVICES {
                  name
                  src
                }
                TECHNOLOGIES {
                  name
                  src
                }
                TECHNOLOGIES2 {
                  name
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
